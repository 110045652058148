import { Button, Checkbox, Dropdown, Icon, Input, Menu } from "antd";
import React, { useState } from "react";
import Text from "antd/lib/typography/Text";
import { useEffect } from "react";
import "./CustomDropdownCheckboxGroupField.less";
import TreeMenu from "./TreeMenu";
import { isEmpty } from "lodash";

const CustomDropdownCheckboxGroupField = ({ optionList = [], treeOptionList = {}, dropDownText, hideSearch, label, defaultValues, input, overlayClassName = "", allowAddOption = false, setAdditionalOption = () => {} }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isReverse, setReverse] = useState(false);

  useEffect(() => {
    if (optionList.length > 0) {
      const matches = optionList.map(item => {
        if (item.label.toLowerCase().includes(searchText.toLowerCase()) || item.value.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }).filter(Boolean);
      setList(matches);
    }
  }, [optionList, searchText]);

  useEffect(() => {
    defaultValues && setSelectedOptions(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (input.value) {
      setSelectedOptions(input.value);
    }
  }, [input.value]);

  const onCheckOptionHandler = (values) => {
    setSelectedOptions(values);
    input.onChange(values);
  }
  
  const onAddOptionHandler = (value) => {
    if (!allowAddOption) return
    setAdditionalOption(value);
    input.onChange([...selectedOptions, value]);
    setSearchText("")
  }

  const onSelectAllHandler = (e) => {
    if (e.target.checked) {
      const allValues = list.map(item => item.value);
      setSelectedOptions(allValues);
      input.onChange(allValues);
    } else {
      setSelectedOptions([]);
      input.onChange([]);
    }
  }

  const onResetHandler = () => {
    setSelectedOptions([]);
    input.onChange([]);
  }

  const onOkHandler = () => {
    setDropdownVisible(false);
  }

  const allChecked = list.length > 0 && selectedOptions.length === list.length;

  const checkboxElement = [
    <Checkbox key='select-all' style={{ marginBottom: 8 }} onChange={onSelectAllHandler} checked={allChecked}>Select All</Checkbox>,
    <Checkbox.Group value={selectedOptions} onChange={onCheckOptionHandler} key='cb-group' options={list} />,
  ]

  const menu = (
    <Menu>
      <div className="custom-checkbox-container">
        {isReverse && (
          <div className="checkbox-inner-container">
            {checkboxElement.reverse()}
          </div>
        )}
        {(allowAddOption && searchText) && (
          <div className="ant-select-dropdown-menu-item ant-select-dropdown-menu-item-selected" 
            onClick={() => onAddOptionHandler(searchText)}
          >
            Add "{searchText}"
          </div>
        )}
        {!hideSearch && <div className="search-box">
          <Input placeholder="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
        </div>}
        {!isReverse && (
          <div className="checkbox-inner-container">
            {checkboxElement}
          </div>
        )}
        <div className="dropdown-buttons">
          <Button size="small" onClick={onResetHandler}>Reset</Button>
          <Button size="small" type="primary" onClick={onOkHandler}>OK</Button>
        </div>
      </div>
    </Menu>)

  useEffect(() => {
    if (dropdownVisible) {
      setReverse(false)
      setTimeout(() => {
        if (document.querySelector('.custom-dropdown-body-part')) {
          let domX = document.querySelector('.custom-dropdown-body-part').getBoundingClientRect().bottom;
          domX > 0 ? setReverse(true) : setReverse(false);
        } else if (document.querySelector('.custom-dropdown-modality')) {
          let domX = document.querySelector('.custom-dropdown-modality').getBoundingClientRect().bottom;
          domX > 0 ? setReverse(true) : setReverse(false);
        } else {
          setReverse(false)
        }
      }, 0);
    }
  }, [dropdownVisible]);

  return (
    <div className="custom-dropdown-checkbox-container">
      <div className="dropdown-label">{label}</div>
      <Dropdown
        overlay={!isEmpty(treeOptionList) ? (
          <TreeMenu 
            setDropdownVisible={setDropdownVisible}
            hideSearch={hideSearch}
            data={treeOptionList}
            onChange={input.onChange} 
          />
        ) : menu}
        className={`custom-dropdown ${selectedOptions.length > 0 ? 'active-dropdown' : ''}`}
        trigger={['click']}
        placement={"bottomRight"}
        visible={dropdownVisible}
        overlayClassName={overlayClassName}
        onVisibleChange={(value) => {
          setDropdownVisible(value);
        }}
      >
        <div style={{ cursor: "pointer" }} className='dropdown-box'>
          <Text className={` mr-1 ${selectedOptions.length > 0 ? 'dropdown-text' : 'dropdown-text-placeholder'}`}>
            {selectedOptions.length > 0 ? selectedOptions.join(", ") : dropDownText ? dropDownText : "Select"}
          </Text>
          <Icon type="down" />
        </div>
      </Dropdown>
    </div>
  );
};

export default CustomDropdownCheckboxGroupField;