import { t } from 'i18next';
import moment from 'moment';

export const dateFilters = {
  TODAY: moment().format('l'),
  THIS_WEEK: moment()
    .subtract(7, 'days')
    .format('l'),
  THIS_MONTH: moment()
    .subtract(30, 'days')
    .format('l'),
  LAST_MONTH: moment()
    .subtract(60, 'days')
    .format('l')
};

export const studyPriorities = [
  'routine',
  'expedite',
  'priority',
  'urgent',
  'emergent'
];
export const labeledStudyPriorities = [
  'expedite',
  'priority',
  'urgent', 
  'emergent'
];

const { TODAY, THIS_WEEK, THIS_MONTH, LAST_MONTH } = dateFilters;

export const dateFilterStrings = {
  TODAY: 'today',
  THIS_WEEK: 'week',
  CUSTOM: 'custom'
};

export const dateOptions = [
  { value: TODAY, label: 'dashboard.today' },
  {
    value: THIS_WEEK,
    label: 'dashboard.this_week'
  },
  {
    value: THIS_MONTH,
    label: 'dashboard.this_month'
  },
  {
    value: LAST_MONTH,
    label: 'dashboard.last_month'
  }
];

export const facilityOptions = [
  { value: 'ALL', label: t('table.all_source_facilities') },
  { value: 'Source Facility ABC', label: 'Source Facility ABC' }
];

export const sentToOptions = [
  { value: 'ALL', label: t('table.all_sent_to') },
  { value: 'My Facility/Provider', label: 'My Facility/Provider' }
];

export const modalityOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'CT', label: 'CT' },
  { value: 'MR', label: 'MR' },
  { value: 'DR', label: 'DR' },
  { value: 'US', label: 'US' },
  { value: 'NM', label: 'NM' },
  { value: 'DS', label: 'DS' },
  { value: 'OT', label: 'OT' },
  { value: 'CR', label: 'CR' },
  { value: 'OP', label: 'OP' }
];

export const fullModalityOptions = [
  { label: 'Computed Radiography (CR)', value: 'CR' },
  { label: 'Computed Tomography (CT)', value: 'CT' },
  { label: 'Digital Radiography (DX)', value: 'DX' },
  { label: 'Electrocardiography (ECG)', value: 'ECG' },
  { label: 'Magnetic Resonance (MR)', value: 'MR' },
  { label: 'Mammography (MG)', value: 'MG' },
  { label: 'Nuclear Medicine (NM)', value: 'NM' },
  { label: 'Ophthalmic Photography (OP)', value: 'OP' },
  { label: 'Other (OT)', value: 'OT' },
  { label: 'Positron emission tomography (PET)', value: 'PT' },
  { label: 'Radio Fluoroscopy (RF)', value: 'RF' },
  { label: 'X-Ray Angiography (XA)', value: 'XA' },
  { label: 'Ultrasound (US)', value: 'US' }
];

export const reportingOptions = [
  { value: 'all', label: t('table.all_reporting_radiologists') }
];

export const viewOptions = [
  { value: 'RVU', label: 'RVU' },
  { value: 'COUNT', label: 'Count' }
];

export const bodyPartOptions = [
  { value: 'all', label: 'All' },
  { value: 'brain', label: 'Brain' },
  { value: 'chest', label: 'Chest' },
  { value: 'wrist', label: 'Wrist' }
];

export const urgencyOptions = [
  { value: 'routine', label: 'Routine' },
  { value: 'urgent', label: 'Urgent' }
];

export const newUrgencyOptions = [
  { value: 'Critical', text: 'Critical' },
  { value: 'STAT', text: 'STAT' },
  { value: 'Urgent', text: 'Urgent' },
  { value: 'Routine', text: 'Routine' },
];

export const connectionTypeOptions = [
  { value: 'AI Appliance', label: 'AI Appliance' },
  { value: 'Imaging Modality', label: 'Imaging Modality' },
  { value: 'Viewing Workstation', label: 'Viewing Workstation' },
  { value: 'RIS/PACS or VNA', label: 'RIS/PACS or VNA' },
  { value: 'AlemHealth Device', label: 'AlemHealth Device' }
];

export const connectionTypeOptionsWithDicomInfo = [
  'AI Appliance',
  'Imaging Modality',
  'Viewing Workstation',
  'RIS/PACS or VNA',
  'Other'
];

export const manufacturerOptions = [
  { value: 'GE', label: 'GE' },
  { value: 'Siemens', label: 'Philips' },
  { value: 'AGFA', label: 'AGFA' },
  { value: 'Fuji', label: 'Fuji' },
  { value: 'Carestream', label: 'Carestream' },
  { value: 'Olympus', label: 'Olympus' },
  { value: 'AlemHealth', label: 'AlemHealth' },
  { value: 'TopCon', label: 'TopCon' }
];

export const deviceModalOptions = [
  { value: 'AlemBox', label: 'AlemBox' },
  { value: 'AlemHub', label: 'AlemHub' },
  { value: 'Connect Uploader', label: 'Connect Uploader' }
];

// # MODALITIES
// # CR    Computed Radiography √
// # CT    Computed Tomography √
// # DX    Digital Radiography √
// # ECG    Electrocardiography √
// # MG    Mammography √
// # MR    Magnetic Resonance √
// # NM    Nuclear Medicine √
// # OP    Ophthalmic Photography √
// # OT    Other
// # PT    Positron emission tomography (PET) √
// # RF    Radio Fluoroscopy √
// # XA    X-Ray Angiography √
// # US    Ultrasound √
// # XA    X-Ray Angiography


export const daysOption = [
  { text: "Monday", value: "Monday" },
  { text: "Tuesday", value: "Tuesday" },
  { text: "Wednesday", value: "Wednesday" },
  { text: "Thursday", value: "Thursday" },
  { text: "Friday", value: "Friday" },
  { text: "Saturday", value: "Saturday" },
]

export const newModalityOptions = [
  {
    title: "X-Ray", key: "X-Ray", isMostUsed: true, children: [
      { title: "CR", key: "CR", isMostUsed: true },
      { title: "DX", key: "DX", isMostUsed: true },
    ]
  },
  { title: "CT", key: "CT", isMostUsed: true },
  { title: "MR", key: "MR", isMostUsed: true },
  { title: "US", key: "US", isMostUsed: true },
  { title: "MG", key: "MG", isMostUsed: true },
  { title: "ECG", key: "ECG" },
  { title: "NM", key: "NM" },
  { title: "OP", key: "OP" },
  { title: "OT", key: "OT" },
  { title: "PT", key: "PT" },
  { title: "RF", key: "RF" },
  { title: "XA", key: "XA" },
];

export const newBodyPartsOptions = [
  { label: "Head", value: "head" },
  { label: "Brain", value: "brain" },
  { label: "Face", value: "face" },
  { label: "Neck", value: "neck" },
  { label: "Chest", value: "chest" },
  { label: "Breast", value: "breast" },
  { label: "Heart", value: "heart" },
  { label: "Abdomen", value: "abdomen" },
  { label: "Liver", value: "liver" },
  { label: "Kidneys", value: "kidneys" },
  { label: "Pancreas", value: "pancreas" },
  { label: "Adrenal Glands", value: "adrenal glands" },
  { label: "Bladder", value: "bladder" },
  { label: "Prostate", value: "prostate" },
  { label: "Uterus", value: "uterus" },
  { label: "Ovaries", value: "ovaries" },
  { label: "Pelvis", value: "pelvis" },
  { label: "Shoulder", value: "shoulder" },
  { label: "Elbow", value: "elbow" },
  { label: "Wrist", value: "wrist" },
  { label: "Hand", value: "hand" },
  { label: "Hip", value: "hip" },
  { label: "Knee", value: "knee" },
  { label: "Ankle", value: "ankle" },
  { label: "Foot", value: "foot" },
  { label: "Full Spine", value: "full spine" },
  { label: "Cervical Spine", value: "cervical spine" },
  { label: "Thoracic Spine", value: "thoracic spine" },
  { label: "Lumbar Spine", value: "lumbar spine" },
  { label: "Thoracolumbar Spine", value: "thoracolumbar spine" },
  { label: "Sacrum", value: "sacrum" },
  { label: "Coccyx", value: "coccyx" },
  { label: "Whole Body", value: "whole body" },
  { label: "Paranasal Sinuses", value: "paranasal sinuses" },
  { label: "Thyroid", value: "thyroid" },
  { label: "Esophagus", value: "esophagus" },
  { label: "Aorta", value: "aorta" },
  { label: "Vascular System", value: "vascular system" },
  { label: "Skull", value: "skull" },
  { label: "Soft Tissues", value: "soft tissues" },
  { label: "Empty", value: "empty" }
];


export const urgencyList = [
  { value: 'Critical', label: 'Critical' },
  { value: 'STAT', label: 'STAT' },
  { value: 'Urgent', label: 'Urgent' },
  { value: 'Routine', label: 'Routine' },
];

export const dayWeekOptions = ["Everyday", "Every Weekday", "Every Weekend"]

export const dayOptions = [
  { label: "Mon", value: "Monday" },
  { label: "Tue", value: "Tuesday" },
  { label: "Wed", value: "Wednesday" },
  { label: "Thu", value: "Thursday" },
  { label: "Fri", value: "Friday" },
  { label: "Sat", value: "Saturday" },
  { label: "Sun", value: "Sunday" },
]

export const timeOptions = [
  { label: "Minute", value: "minutes" },
  { label: "Hour", value: "hours" },
  { label: "Day", value: "days" },
]

export const signatureFontOptions = [
  { value: 'Inter', text: 'Signature' },
  { value: 'Signatura monoline script', text: 'Signature' },
  { value: 'Dancing script', text: 'Signature' },
  { value: 'Sacramento', text: 'Signature' },
  { value: 'Kaushan script', text: 'Signature' },
];

// export const qualificationOptions = [
//   { label: "Musculoskeletal Imaging", value: "Musculoskeletal Imaging" },
//   { label: "Neuroradiology", value: "Neuroradiology" },
//   { label: "Nuclear Medicine", value: "Nuclear Medicine" },
//   { label: "Pediatric Radiology", value: "Pediatric Radiology" },
//   { label: "Thoracic Imaging", value: "Thoracic Imaging" }
// ];

export const qualificationOptions = [
  "Musculoskeletal Imaging",
  "Neuroradiology",
  "Nuclear Medicine",
  "Pediatric Radiology",
  "Thoracic Imaging"
];
