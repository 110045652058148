/* import { Route } from 'react-router-dom';
import React, { Fragment } from 'react';
import NoAdmin from '../pages/no_admin';
import { Login } from '../pages';
import IndexUser from '../pages/users';
import IndexFacilities from '../pages/facilities';
import IndexPhysicians from '../pages/physicians';
import RequestServiceProvider from '../pages/radiologist/RequestService';
import IndexRadiologist from '../pages/radiologist';
import IndexPlan from '../pages/payments/components/deprecated';
import IndexSettings from '../pages/settings'; */

export const pages = {
  login: '/login',
  noAdmin: '/no_admin',
  dashboard: '/dashboard',
  user: '/user',
  facilities: '/facilities',
  phisicians: '/physicians/manage',
  requestServiceProvider:
    '/network/manage_radiologists/request_service_provider',
  radiologists: '/network/manage_radiologists',
  plan: '/subscriptions',
  settings: '/settings',
  onboarding: '/onboarding',
  secondOpinionBranding: '/second-opinion/branding',
  secondOpinionUploader: '/second-opinion/uploader',
  devices: '/devices',
  onPremisesDevices: '/on-premises-devices',
  routing: '/routing',
  schedule: '/schedule',
  routes: '/routes',
  radiologistPool: '/routing/radiologist-pool',
  createRoute: '/routing/routes/create-route',
  editRoute: '/routing/routes/edit-route/:routeId',
  cloudDevices: '/cloud-devices',
  utilisationRates: '/utilisation-rates',
  referralPatterns: '/referral/referral-patterns',
  referringPhysicians: '/referral/referring-physicians',
  referringFacilities: '/referral/referring-facilities'
};

export const routeList = [
  {
    id: '1',
    icon: 'dashboard',
    name: 'Dashboard',
    route: '/dashboard'
  },
  {
    id: '2',
    name: 'User Administration',
    icon: 'user',
    route: pages.user
  },
  {
    id: '3',
    name: 'Radiologist Network',
    icon: 'network',
    route: '/network'
  },
  {
    id: '31',
    menuParentId: '3',
    breadcrumbParentId: '3',
    name: 'Manage Radiologists',
    route: pages.radiologists
  },
  // {
  //   id: '32',
  //   menuParentId: '3',
  //   breadcrumbParentId: '3',
  //   name: 'Network Invitations',
  //   route: '/network/invitations'
  // },
  {
    id: '311',
    breadcrumbParentId: '31',
    name: 'Request service provider',
    route: pages.requestServiceProvider
  },
  {
    id: '4',
    name: 'My Facilities',
    icon: 'facilities',
    route: pages.facilities
  },
  {
    id: '8',
    name: 'Referral',
    icon: 'refuser',
    route: '/physicians'
  },
  // {
  //   id: '81',
  //   menuParentId: '8',
  //   breadcrumbParentId: '8',
  //   name: 'Manage Physicians',
  //   route: pages.phisicians
  // },
  {
    id: '82',
    menuParentId: '8',
    breadcrumbParentId: '8',
    name: 'Referral Patterns',
    route: pages.referralPatterns
  },
  {
    id: '83',
    menuParentId: '8',
    breadcrumbParentId: '8',
    name: 'Referring Physicians',
    route: pages.referringPhysicians
  },
  {
    id: '84',
    menuParentId: '8',
    breadcrumbParentId: '8',
    name: 'Referring Facilities',
    route: pages.referringFacilities
  },
  {
    id: '5',
    name: 'Manage Subscription',
    icon: 'plan',
    route: pages.plan
  },
  // {
  //   id: '5',
  //   name: 'Plan & Usage',
  //   icon: 'plan',
  //   route: pages.plan
  // },
  {
    id: '6',
    name: 'Settings',
    icon: 'setting',
    route: pages.settings
  },
  {
    id: '7',
    name: 'Support',
    icon: 'support',
    route: 'http://support.alemhealth.com'
  },
  {
    id: '9',
    name: 'Second Opinion',
    icon: 'medical',
    route: '/second-opinion'
  },
  {
    id: '91',
    menuParentId: '9',
    name: 'Uploader Settings',
    breadcrumbParentId: '9',
    route: pages.secondOpinionUploader
  },
  {
    id: '92',
    menuParentId: '9',
    name: 'Branding',
    breadcrumbParentId: '9',
    route: pages.secondOpinionBranding
  },
  {
    id: '11',
    name: 'Devices',
    icon: 'cluster',
    route: pages.devices
  },
  {
    id: '111',
    menuParentId: '11',
    name: 'On-Premises Devices',
    breadcrumbParentId: '11',
    route: pages.onPremisesDevices
  },
  {
    id: '112',
    menuParentId: '11',
    name: 'Cloud Devices',
    breadcrumbParentId: '11',
    route: pages.cloudDevices
  },
  {
    id: '12',
    name: 'Routing Preferences',
    icon: 'routes',
    route: '/routing',
  },
  {
    id: '121',
    menuParentId: '12',
    name: 'Routes',
    breadcrumbParentId: '12',
    route: pages.routes
  },
  // {
  //   id: '122',
  //   menuParentId: '12',
  //   name: 'Radiologist Pool',
  //   breadcrumbParentId: '12',
  //   route: pages.radiologistPool
  // },
  {
    id: '123',
    menuParentId: '12',
    name: ['Routes', 'Create Route'],
    breadcrumbParentId: '12',
    route: pages.createRoute
  },
  {
    id: '124',
    menuParentId: '12',
    name: ['Routes', 'Edit Route'],
    breadcrumbParentId: '12',
    route: pages.editRoute
  },
  {
    id: '10',
    name: 'Getting Started',
    icon: 'onboarding',
    route: pages.onboarding
  },
];

export const getRoutes = admin => {
  if (admin === '1') {
    return [
      '1',
      '2',
      '3',
      '31',
      '311',
      '32',
      '4',
      '5',
      '6',
      '7',
      '9',
      '91',
      '92',
      '10',
      '11',
      '111',
      '112',
      '8',
      '81',
      '82',
      '83',
      '84',
      '12',
      '121',
      '122',
      '123',
      '124',
      ];
  }
  return ['1', '2', '3', '31', '311', '32', '4', '5', '6', '7', '8', '81'];
};
