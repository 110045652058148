import AuthService from '../index';
import Logger from '../../utils/logger';
const API_SERVICE = 'routes';
const BASE_ROUTE = '/routing-rules';
const SCHEDULE_ROUTE = '/schedules';

export const getRouteListAPI = async (profile) => {
  Logger.log('Get in route list api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, BASE_ROUTE, {})
    .then((response) =>
      response
    );
};

export const getRouteByIdAPI = async (profile, id) => {
  Logger.log('Get in route detail api called', id);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then((response) =>
      response
    );
};

export const createRouteListAPI = async (profile, data) => {
  Logger.log('Create route API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'POST',
    body: JSON.stringify(data)
  });
};

export const updateRouteByIdAPI = async (profile, data, id) => {
  Logger.log('Update route API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const deleteRouteByIdAPI = async (profile, id) => {
  Logger.log('delete route api called', id);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
      method: 'DELETE',
    })
    .then((response) =>
      response
    );
};


export const getScheduleListAPI = async (profile) => {
  Logger.log('Get in Schedule list api called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, SCHEDULE_ROUTE, {})
    .then((response) =>
      response
    );
};

export const createScheduleListAPI = async (profile, data) => {
  Logger.log('Create Schedule API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, SCHEDULE_ROUTE, {
    method: 'POST',
    body: JSON.stringify(data)
  });
};

export const updateScheduleByIdAPI = async (profile, data, id) => {
  Logger.log('Update Schedule API endpoint called with', data);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${SCHEDULE_ROUTE}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const deleteScheduleByIdAPI = async (profile, id) => {
  Logger.log('delete Schedule api called', id);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${SCHEDULE_ROUTE}/${id}`, {
      method: 'DELETE',
    })
    .then((response) =>
      response
    );
};