import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, Checkbox, Col, Icon, Input, Row, Select, Steps, Switch, TimePicker, Divider, Modal, Avatar, Radio, Dropdown, Menu, Empty, Typography, Spin, Breadcrumb } from "antd";
import StudyDetailForm from "./components/StudyDetailForm";
import FooterButton from "./components/FooterButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues } from "redux-form";
import { editRouteRequestAction, getInternalFacilitiesAction, getInternalRadiologistsAction, getInternalRadiologistsStatusAction, getProviders, getScheduleListRequestAction, getSingleRouteRequestAction, postCreateRouteRequestAction, postCreateScheduleRequestAction } from "../../../store/actions";
import { dayOptions, dayWeekOptions, timeOptions } from "../../../store/constants";
import timeZone from "../../../store/constants/timeZone";
import moment from "moment";
import RouteForm from "./components/RouteForm";
import momentTimezone from "moment-timezone";
import "./index.less"

const { confirm } = Modal;
const { Option } = Select;
const { Step } = Steps;
const { Text, Title } = Typography;

const ProviderStatusIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.59375 0.375C4.40727 0.375 4.22843 0.449079 4.09657 0.580941C3.9647 0.712802 3.89062 0.891645 3.89062 1.07812V3.89062H1.07812C0.891645 3.89062 0.712802 3.9647 0.580941 4.09657C0.449079 4.22843 0.375 4.40727 0.375 4.59375V7.40625C0.375 7.59273 0.449079 7.77157 0.580941 7.90343C0.712802 8.0353 0.891645 8.10938 1.07812 8.10938H3.89062V10.9219C3.89062 11.1084 3.9647 11.2872 4.09657 11.4191C4.22843 11.5509 4.40727 11.625 4.59375 11.625H7.40625C7.59273 11.625 7.77157 11.5509 7.90343 11.4191C8.0353 11.2872 8.10938 11.1084 8.10938 10.9219V8.10938H10.9219C11.1084 8.10938 11.2872 8.0353 11.4191 7.90343C11.5509 7.77157 11.625 7.59273 11.625 7.40625V4.59375C11.625 4.40727 11.5509 4.22843 11.4191 4.09657C11.2872 3.9647 11.1084 3.89062 10.9219 3.89062H8.10938V1.07812C8.10938 0.891645 8.0353 0.712802 7.90343 0.580941C7.77157 0.449079 7.59273 0.375 7.40625 0.375H4.59375Z" fill="#05658A" />
  </svg>
);


const CreateRoute = (props) => {
  const params = useParams()
  const history = useHistory();
  const { studyDetailValues, routeFormValues = {}, loading, getFacilities, facilityList, routeDetails, getRadiologistsAction, radiologistList, getProviders, providerList, getInternalRadiologistsStatus, activeRadiologists = [], createRouteRequest, getSingleRoute, updateRouteRequest, getSchedules, scheduleList, createSchedule } = props;
  const timeZoneList = Object.keys(timeZone).map((key) => ({
    label: timeZone[key],
    value: key,
  }))
  const dropdownRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [searchFacility, setSearchFacility] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [selectedDays, setSelectedDays] = useState(dayOptions.map((day) => day.value));
  const [selectedDayWeek, setSelectedDayWeek] = useState("Everyday");
  const [startTime, setStartTime] = useState(moment("00:00", "HH:mm"));
  const [endTime, setEndTime] = useState(moment("23:59", "HH:mm"));
  const [allowWeekend, setAllowWeekend] = useState(false);
  const [allDay, setAllDay] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("radiologist");
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [initialRouteFormValues, setInitialRouteFormValues] = useState({ name: "", description: "" });
  const [initialStudyDetailFormValues, setInitialStudyDetailFormValues] = useState({ body_parts: [], modality: [], urgency: [] });
  const [currentTime, setCurrentTime] = useState("");
  const [utcOffset, setUtcOffset] = useState("");
  const [location, setLocation] = useState("");
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions()
  const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone && timeZoneList.find(timeZone => timeZone.value === currentTimezone.timeZone).label || "");
  const [currentSelectedTime, setCurrentSelectedTime] = useState(null);
  const [currentSelectedTimeZone, setCurrentSelectedTimeZone] = useState(null);

  useEffect(() => {
    // getFacilities();
    getProviders();
    getRadiologistsAction();
    getSchedules()
    // getInternalRadiologistsStatus()  
  }, []);

  useEffect(() => {
    if (params.routeId) {
      getSingleRoute(params.routeId)
    }
  }, [params.routeId]);

  useEffect(() => {
    startTime && endTime && moment(startTime, "HH:mm").format("HH:mm") == "00:00" && moment(endTime, "HH:mm").format("HH:mm") == "23:59" ? setAllDay(true) : setAllDay(false)
  }, [startTime, endTime]);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (params.routeId && routeDetails && routeDetails.id) {
      let currentRoute = routeDetails
      setInitialRouteFormValues({
        name: currentRoute.name,
        description: currentRoute.description || "",
      })
      setInitialStudyDetailFormValues({
        body_parts: currentRoute.body_parts,
        modality: currentRoute.modality,
        urgency: currentRoute.urgency,
      })

      if (currentRoute.source_facility && currentRoute.source_facility.length > 0) {
        setSelectedFacility(currentRoute.source_facility.map((facility) => {
          const facilityDetail = facilityList.find(fac => fac.guid == facility)
          return {
            ...facilityDetail
          }
        }))
      }
      let daysArray = currentRoute.schedule.days
      if (daysArray.length > 0) {
        setSelectedDays(daysArray)
        let isWeekend = daysArray.length == 2 && daysArray.includes("Saturday") && daysArray.includes("Sunday");
        const weekdaysSet = new Set(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
        const checkWeekDays = daysArray.length === 5 && daysArray.every(day => weekdaysSet.has(day));
        isWeekend && setAllowWeekend(true)
        daysArray.length == dayOptions.length ? setSelectedDayWeek("Everyday") : isWeekend ? setSelectedDayWeek("Weekends") : checkWeekDays ? setSelectedDayWeek("Every Weekday") : setSelectedDayWeek("")
      }
      // setSelectedDayWeek(currentRoute.schedule.days[0])
      if (currentRoute.schedule.start_time && currentRoute.schedule.end_time) {
        currentRoute.schedule.start_time == "00:00" && currentRoute.schedule.end_time == "23:59" && setAllDay(true);
        setStartTime(moment(currentRoute.schedule.start_time, "HH:mm").format("HH:mm"))
        setEndTime(moment(currentRoute.schedule.end_time, "HH:mm").format("HH:mm"))
      }
      if (currentRoute.schedule && currentRoute.schedule.days && currentRoute.schedule.days.length > 0) {
        setAllowWeekend(currentRoute.schedule.days.includes("Saturday") && currentRoute.schedule.days.includes("Sunday"))
      }
      let assigneeArray = []
      if (currentRoute.assignees && currentRoute.assignees.length > 0) {
        assigneeArray = currentRoute.assignees.map((assignee) => {
          let userDetail = {}
          if (radiologistList.find(rad => rad.id == assignee.guid)) {
            userDetail = radiologistList.find(rad => rad.id == assignee.guid)
          } else {
            userDetail = providerList.find(rad => rad.id == assignee.guid)
          }
          return {
            ...userDetail,
            userRole: assignee.type,
            time: (assignee.idle_time / 60),
            timeType: 'minutes'
          }
        })
        setSelectedAssignee(assigneeArray)
      }
    }

  }, [params.routeId, facilityList.length, radiologistList.length, providerList.length, routeDetails]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions()
      const formattedTime = new Intl.DateTimeFormat(currentTimezone && currentTimezone.locale || "en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(now);
      const offset = now.getTimezoneOffset();
      const sign = offset <= 0 ? "+" : "-";
      const absOffset = Math.abs(offset);
      const hours = Math.floor(absOffset / 60);
      const minutes = absOffset % 60;
      const formattedOffset = `UTC ${sign}${hours}:${minutes.toString().padStart(2, "0")}`;
      const location = currentTimezone.timeZone.split("/")[1] + ", " + currentTimezone.timeZone.split("/")[0];
      setCurrentTime(formattedTime);
      setUtcOffset(formattedOffset);
      setLocation(location);
    };
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedTimezone) {
        const findTimeZone = timeZoneList.find((timeZone) => timeZone.label === selectedTimezone);
        const options = {
          timeZone: findTimeZone.value,
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        const currentTime = new Intl.DateTimeFormat("en-IN", options).format(new Date());
        const timeZoneOffset = momentTimezone.tz(findTimeZone.value).format('Z');
        setCurrentSelectedTimeZone(timeZoneOffset);
        setCurrentSelectedTime(moment(currentTime, "hh:mm a").format("hh:mm A"));
      }
    }, 1000);

    return () => clearInterval(interval);

  }, [selectedTimezone]);


  const onNext = () => {
    if (currentStep == 0) {
      if (!studyDetailValues || !studyDetailValues.urgency || !studyDetailValues.modality || !studyDetailValues.body_parts || studyDetailValues.urgency.length == 0 || studyDetailValues.modality.length == 0 || studyDetailValues.body_parts.length == 0) {
        setErrorMessage("You must select urgency, modality and body parts in order to continue.");
      } else {
        setErrorMessage("");
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep == 1) {
      if (selectedFacility.length > 0) {
        setErrorMessage("");
        setCurrentStep(currentStep + 1);
      } else {
        setErrorMessage("You must select source facility in order to continue.");
      }
    } else if (currentStep == 2) {
      if (selectedSchedule || (selectedDays.length > 0 && startTime && endTime)) {
        setErrorMessage("");
        setCurrentStep(currentStep + 1);
      } else {
        setErrorMessage("You must select date or schedule in order to continue.");
      }
    } else if (currentStep == 3) {
      if (selectedAssignee.length > 0) {
        setErrorMessage("");
        setCurrentStep(currentStep + 1);
      } else {
        setErrorMessage("You must choose radiologist(s) or provider(s) for the radiology pool.");
      }
    } else if (currentStep == 4) {
      if (routeFormValues && routeFormValues.name) {
        let assigneeObj = selectedAssignee.map(i => {
          let convertToSeconds = 0
          if (i.timeType === "minutes") {
            convertToSeconds = i.time * 60
          } else if (i.timeType === "hours") {
            convertToSeconds = i.time * 60 * 60
          } else if (i.timeType === "days") {
            convertToSeconds = i.time * 60 * 60 * 24
          }
          return {
            guid: i.id,
            type: i.userRole,
            idle_time: 0
          }
        });
        let transformedData = {}
        if (params.routeId && routeDetails) {
          transformedData = {
            ...routeDetails
          }
        }
        transformedData = {
          ...transformedData,
          ...studyDetailValues,
          name: routeFormValues.name,
          description: routeFormValues.description,
          source_facility: selectedFacility.map(i => i.guid),
          schedule: {
            days: selectedDays,
            start_time: moment(startTime, "HH:mm").format("HH:mm") + currentSelectedTimeZone,
            end_time: moment(endTime, "HH:mm").format("HH:mm") + currentSelectedTimeZone,
          },
          assignees: assigneeObj,
          assignment_order: [],
        }
        params.routeId ? updateRouteRequest({ ...transformedData, history: history, id: params.routeId }) : createRouteRequest({ ...transformedData, history: history })
      } else {
        setErrorMessage("You must enter route name and description in order to continue.");
      }
    }
  }

  const onPrevious = () => {
    if (currentStep > 0) {
      setErrorMessage("");
      setCurrentStep(currentStep - 1);
    }
  }


  const onClickFacilityItem = (item) => {
    let array = [...selectedFacility]
    if (array.length > 0) {
      if (array.some((i) => i.guid === item.guid)) {
        array = array.filter((i) => i.guid !== item.guid)
        setSelectedFacility(array)
      } else {
        array.push(item)
        setSelectedFacility(array)
      }
    } else {
      array.push(item)
      setSelectedFacility(array)
    }
  }

  const onSelectAllFacility = () => {
    if (facilityList.length !== selectedFacility.length) {
      setSelectedFacility(facilityList)
    } else {
      setSelectedFacility([])
    }
  }


  const onSelectDayWeek = (value) => {
    setSelectedDayWeek(value);
    setAllowWeekend(false);
    let values = [];
    if (value === "Everyday") {
      values = dayOptions.map((day) => day.value);
    } else if (value === "Every Weekday") {
      values = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    } else if (value === "Every Weekend") {
      values = ["Saturday", "Sunday"];
      setAllowWeekend(true);
    }
    setSelectedDays(values);
  };

  const toggleDay = (day) => {
    setSelectedDayWeek("");
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  const selectAllDays = () => {
    let values = [];
    setSelectedDayWeek("");
    if (selectedDays.length !== dayOptions.length) {
      setSelectedDayWeek("Everyday");
      values = dayOptions.map((day) => day.value);
    }
    setSelectedDays(values);
  };

  const onToggleTime = () => {
    setAllDay(!allDay);
    if (!allDay) {
      setStartTime(moment("00:00", "HH:mm"));
      setEndTime(moment("23:59", "HH:mm"));
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  };

  const getAvatarText = (name) => {
    const words = name.split(' ');
    if (words.length > 1) {
      return words[0].charAt(0) + words[1].charAt(0);
    }
    return name.substring(0, 2);
  };

  const onChangeTime = (value, type = "start") => {
    if (value) {
      type === "start" ? setStartTime(moment(value).format("HH:mm")) : setEndTime(moment(value).format("HH:mm"))
    } else {
      type === "start" ? setStartTime("") : setEndTime("")
    }
  }

  const changeAssigneeTime = (e, index) => {
    let array = [...selectedAssignee]
    array[index] = {
      ...array[index],
      time: e.target.value
    }
    setSelectedAssignee(array)
  }

  const changeAssigneeTimeType = (value, index) => {
    let array = [...selectedAssignee]
    array[index] = {
      ...array[index],
      timeType: value
    }
    setSelectedAssignee(array)
  }

  const onSelectAssignee = (value, type = "Radiologist") => {
    let array = [...selectedAssignee]
    if (array.length > 0) {
      if (array.some((item) => item.id === value.id)) {
        array = array.filter((item) => item.id !== value.id)
        setSelectedAssignee(array);
      } else {
        let newObj = { ...value, time: 30, timeType: "minutes", userRole: type, }
        array.push(newObj)
        setSelectedAssignee(array);
      }
    } else {
      let newObj = { ...value, time: 30, timeType: "minutes", userRole: type }
      array.push(newObj)
      setSelectedAssignee(array);
    }
  }

  const onRemoveAssigneeHandler = (id) => {
    confirm({
      title: 'Are you sure you want to remove this radiologist or provider from the assignee list?',
      content: 'This action will remove them from current assignee list. Please confirm if you want to proceed.',
      okText: 'Yes, Remove',
      icon: <Icon type="delete" style={{ color: 'red' }} />,
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk() {
        let array = [...selectedAssignee]
        array = array.filter((item) => item.id !== id)
        setSelectedAssignee(array);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const changeOnSelectedSchedule = (value) => {
    setSelectedSchedule(value);
    const selectedSchedule = scheduleList.find((schedule) => schedule.id === value)
    let daysArray = selectedSchedule.days
    setStartTime(moment(selectedSchedule.start_time, "HH:mm"))
    setEndTime(moment(selectedSchedule.end_time, "HH:mm"))
    selectedSchedule.start_time == "00:00" && selectedSchedule.end_time == "23:59" && setAllDay(true);
    if (daysArray.length > 0) {
      setSelectedDays(daysArray)
      let isWeekend = daysArray.length == 2 && daysArray.includes("Saturday") && daysArray.includes("Sunday");
      isWeekend && setAllowWeekend(true)
      const weekdaysSet = new Set(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
      const checkWeekDays = daysArray.length === 5 && daysArray.every(day => weekdaysSet.has(day));
      daysArray.length == dayOptions.length ? setSelectedDayWeek("Everyday") : isWeekend ? setSelectedDayWeek("Weekends") : checkWeekDays ? setSelectedDayWeek("Every Weekday") : setSelectedDayWeek("")
    }
  }

  const onClickAddScheduleHandler = () => {
    const body = {
      name: scheduleName,
      start_time: moment(startTime, "HH:mm").format("HH:mm"),
      end_time: moment(endTime, "HH:mm").format("HH:mm"),
      days: selectedDays
    }
    createSchedule({
      ...body, callback: (state) => {
        setVisibleModal(false)
      }
    })
  }
  const onChangeStep = (value) => {
    setErrorMessage("");
    setCurrentStep(value)
  }

  const radiologistMenu = (
    <Menu className="assignee-dropdown-menu">
      <div ref={dropdownRef}>
        {radiologistList && radiologistList.length > 0 ? radiologistList.filter(rad => rad.name.toLowerCase().includes(searchText.toLowerCase())).map((assignee, index) => {
          return <div key={index} className={`assignee-menu-item ${selectedAssignee.some((item) => item.id === assignee.id) ? 'assignee-menu-item-active' : ''}`} onClick={() => onSelectAssignee(assignee, "Radiologist")}>
            <div className={`menu-item`}>
              <div className="assignee-option">
                <div className="assignee-avatar"><Avatar size={40}>{getAvatarText(assignee.name).toUpperCase()}</Avatar> <div className={activeRadiologists.includes(assignee.id) ? `assignee-status-active` : `assignee-status`} /></div>
                <div className="assignee-info">
                  <div className="assignee-name">{assignee.name}</div>
                  <div className="assignee-role">{"Radiologist"}</div>
                </div>
              </div>
              <div><Icon className="dropdown-check-icon" type="check" /></div>
            </div>
          </div>
        }) : <Empty />}
      </div>
    </Menu>
  );

  const providerMenu = (
    <Menu className="assignee-dropdown-menu">
      <div ref={dropdownRef}>
        {providerList && providerList.length > 0 ? providerList.filter(rad => rad.name.toLowerCase().includes(searchText.toLowerCase())).map((assignee, index) => {
          return <div key={index} className={`assignee-menu-item ${selectedAssignee.some((item) => item.id === assignee.id) ? 'assignee-menu-item-active' : ''}`} onClick={() => onSelectAssignee(assignee, "Provider")}>
            <div className={`menu-item`}>
              <div className="assignee-option">
                <div className="assignee-avatar"><Avatar size={40}>{getAvatarText(assignee.name).toUpperCase()}</Avatar><Icon component={ProviderStatusIcon} className="assignee-status-icon" /></div>
                <div className="assignee-info">
                  <div className="assignee-name">{assignee.name}</div>
                  <div className="assignee-role">{"Provider"}</div>
                </div>
              </div>
              <div><Icon className="dropdown-check-icon" type="check" /></div>
            </div>
          </div>
        }) : <Empty />}
      </div>
    </Menu>
  );

  return <div className="create-rout-root">
    <Spin spinning={loading}>
      <div className="routes-header-wrapper">
        <div className="routes-header">
          <Row>
            <Col span={24}>
              <Breadcrumb className='bread'>
                <Breadcrumb.Item>Routing Preferences</Breadcrumb.Item>
                <Breadcrumb.Item>Routes</Breadcrumb.Item>
                <Breadcrumb.Item>{params.routeId ? "Edit Route" : "Create Route"}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={24}>
              <Title level={4} style={{ color: "#000", fontWeight: 600, marginBottom: 8 }}>{params.routeId ? "Edit Route" : "Create Route"}</Title>
              <span style={{ color: "#000", fontSize: 14 }}>{params.routeId ? "Edit " : "Create "} study routes for radiologists/providers based on urgency, modality, body parts, facility, and schedule. </span>
            </Col>
            <Col span={24}>
              <div className="steps-container">
                <div className="steps-card">
                  <Steps current={currentStep} onChange={onChangeStep}>
                    <Step title="Study Details" description="Select urgency, modality and body parts" />
                    <Step disabled={selectedFacility.length == 0} title="Facility" description="Select source facility." />
                    <Step disabled={selectedDays.length == 0 || !startTime || !endTime} title="When" description="Select date or schedule" />
                    <Step disabled={selectedAssignee.length == 0} title="Assignee" description="Choose radiologists/providers, define idle times, and determine the order." />
                    <Step disabled={initialRouteFormValues && !initialRouteFormValues.name} title="Route Details" description="Route name and description" />
                  </Steps>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {
        currentStep == 0 &&
        <div className="routes-content">
          <Card className="routes-card" bordered={true}>
            <Title level={4} style={{ color: "#000", fontWeight: 600, fontSize: 16, textAlign: "left" }}>Study Details</Title>
            <div style={{ marginTop: 15 }}>
              <StudyDetailForm initialValues={initialStudyDetailFormValues} />
            </div>
            <div className="error-message"><Text type="danger"> {errorMessage} </Text></div>
          </Card>
        </div>
      }
      {
        currentStep == 1 &&
        <div className="routes-content">
          <Card className="routes-card facility-card" bordered={true}>
            <Title level={4} style={{ color: "#000", fontWeight: 600, fontSize: 16, textAlign: "left" }}>Source Facility</Title>
            <div style={{ marginTop: 15 }}>
              <Input.Search placeholder="Search" onSearch={value => setSearchFacility(value)} enterButton="Search" size="large" />
            </div>
            <div className="checkbox-container" onClick={() => onSelectAllFacility()}> <Checkbox disabled={facilityList && facilityList.length === 0} checked={facilityList.length > 0 && selectedFacility.length === facilityList.length} onChange={() => onSelectAllFacility()} style={{ marginRight: 10 }} /> Select All</div>
            <div className="facility-selection-container">
              {facilityList && facilityList.length > 0 && facilityList.filter(item => item.name.toLowerCase().includes(searchFacility.toLowerCase())).map((item, index) => {
                return <div className={`facility-element ${selectedFacility.length && selectedFacility.some((i) => i.guid === item.guid) ? "selected-facility" : ""}`} key={index} onClick={() => onClickFacilityItem(item)}>
                  <div>
                    <div className="facility-name">{item.name}</div>
                    <div className="facility-country">{item.country || "-"}</div>
                  </div>
                  <div>
                    {selectedFacility.length && selectedFacility.some((i) => i.guid === item.guid) ? <Icon style={{ fontSize: 16, color: "#15A9E1" }} type="check" /> : ""}
                  </div>
                </div>
              })}
            </div>
            <div className="error-message"><Text type="danger"> {errorMessage} </Text></div>
          </Card>
        </div>
      }
      {
        currentStep == 2 &&
        <div className="routes-content">
          <Card className="routes-card" bordered={true}>
            <Title level={4} style={{ color: "#000", fontWeight: 600, fontSize: 16, textAlign: "left" }}>When</Title>
            <div style={{ marginTop: 15 }}>
              <Select placeholder="Select custom schedule" style={{ width: "100%" }} value={selectedSchedule} onChange={(value) => { changeOnSelectedSchedule(value) }}>
                {scheduleList.map((item, index) => {
                  return <Option key={index} value={item.id}>{item.name}</Option>
                })}
              </Select>
            </div>
            <div className="day-container">
              <div className="title">Day</div>
              <div className="day-week-container">
                {dayWeekOptions.map((item, index) => {
                  return <div key={index} className={`day-week-element ${selectedDayWeek == item ? "active-day" : ""}`} onClick={() => onSelectDayWeek(item)}>{item}</div>
                })}
              </div>
              <div className="days-container">
                {dayOptions.map((item, index) => {
                  return <div key={index} className={`day-week-element ${selectedDays.some(day => day == item.value) ? "active-day" : ""}`} onClick={() => toggleDay(item.value)}>{item.label}</div>
                })}
                <Button type="link" style={{ padding: 0, height: "unset" }} onClick={selectAllDays}>Select all</Button>
              </div>
              <div className="switch-container">
                <Switch
                  className="switch"
                  checked={allowWeekend}
                  onChange={() => setAllowWeekend(!allowWeekend)}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />} /> Allow on weekend
              </div>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            <div className="timezone-box">
              <div><span style={{ color: "#000000" }}>Your current time is</span> <strong>{currentTime}</strong> <span className="utc-location-text">{"(" + utcOffset + ")"}</span> <span className="utc-location-text">{location}</span></div>
              {currentTimezone.timeZone !== timeZoneList.find(item => item.label === selectedTimezone).value && <div><span style={{ color: "#000000" }}>Current Time in Selected Timezone</span> <strong>{currentSelectedTime}</strong> <span className="utc-location-text">{timeZoneList.find(item => item.label === selectedTimezone).label}</span></div>}
            </div>
            <div className="day-container">
              <div style={{ marginBottom: 10 }}>
                <div className="title">Location or Timezone</div>
                <Select
                  showSearch
                  value={selectedTimezone}
                  onChange={(value) => {
                    const findTimeZone = timeZoneList.find(item => item.label === value)
                    setSelectedTimezone(value)
                  }}
                  style={{ width: "100%", marginTop: 5 }}
                  suffixIcon={<Icon type="search" />}
                >
                  {timeZoneList.map((item, index) => {
                    let showText = item.label.split(" ");
                    let country = item.label.split(" ").slice(1).join(" ");
                    return <Option key={index} value={item.label}><span className="time-zone-utc">{showText[0]}</span> <span>{country}</span></Option>
                  })}
                </Select>
              </div>
              <div className="title">Time</div>
              <div className="time-picker-container">
                <TimePicker className="time-picker" value={startTime ? moment(startTime, "HH:mm") : ""} format="HH:mm" style={{ marginRight: 15 }} onChange={(value) => onChangeTime(value, "start")} placeholder="Start time" />
                <TimePicker className="time-picker" value={endTime ? moment(endTime, "HH:mm") : ""} format="HH:mm" onChange={(value) => onChangeTime(value, "end")} placeholder="End time" />
              </div>
              <div className="switch-container">
                <Switch
                  className="switch"
                  onChange={onToggleTime}
                  checked={allDay}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />} /> All day
              </div>
            </div>
            <div className="link-btn" onClick={() => setVisibleModal(true)}>Save as new custom schedule</div>
            <div className="error-message"><Text type="danger"> {errorMessage} </Text></div>
          </Card>
        </div>
      }
      {
        currentStep == 3 &&
        <div className="routes-content">
          <Card className="routes-card facility-card" bordered={true}>
            <Title level={4} style={{ color: "#000", fontWeight: 600, fontSize: 16, textAlign: "left" }}>Assignee</Title>
            <div style={{ marginTop: 15 }}>
              <Radio.Group size="small" value={userType} buttonStyle="solid" onChange={value => setUserType(value.target.value)}>
                <Radio.Button value="radiologist">Radiologist</Radio.Button>
                <Radio.Button value="provider">Provider</Radio.Button>
              </Radio.Group>
            </div>
            <div style={{ marginTop: 20 }} >
              <Dropdown overlay={userType === "radiologist" ? radiologistMenu : providerMenu} visible={dropdownVisible} trigger={['click']} className="day-filter-dropdown">
                <Input.Search
                  value={searchText}
                  onClick={() => setDropdownVisible(true)}
                  onChange={e => setSearchText(e.target.value)}
                  enterButton="Search"
                  size="large"
                  placeholder="Select Assignee"
                />
              </Dropdown>
            </div>
            <div className="selected-assignee-container">
              {selectedAssignee.map((assignee, index) => {
                let userDetail = {}
                if (radiologistList.find(rad => rad.id == assignee.id)) {
                  userDetail = radiologistList.find(rad => rad.id == assignee.id) || {}
                } else {
                  userDetail = providerList.find(rad => rad.id == assignee.id) || {}
                }
                return <div className="selected-assignee-card" key={index}>
                  <div className="card-header">
                    <div className="checkbox">
                      <Checkbox checked={true} onClick={() => onRemoveAssigneeHandler(assignee.id)} />
                    </div>
                    <div className="avatar-box">
                      <Avatar className="assignee-avatar" size={40}>{getAvatarText(userDetail.name).toUpperCase()}</Avatar>
                      {assignee.userRole === "Radiologist" ? <div className={activeRadiologists.includes(assignee.id) ? `assignee-status-active` : `assignee-status`} /> : <Icon component={ProviderStatusIcon} className="assignee-status-icon" />}
                    </div>
                    <div>
                      <div className="assignee-name">{userDetail.name}</div>
                      <div className="assignee-role">{assignee.userRole}</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Row type="flex">
                      <Col span={10}>
                        <div className="label">Facility</div>
                        <div className="value">{userDetail.userMetadata && userDetail.userMetadata.homeFacility || "-"}</div>
                      </Col>
                      <Col span={14}>
                        <div className="label">Subspecialty</div>
                        <div className="user-specialty">{userDetail.userMetadata && userDetail.userMetadata.specialty.join(", ")}</div>
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="card-footer">
                    <span className="time-text">Assign study to next assignee if no response within</span> <Input type="number" onChange={(e) => changeAssigneeTime(e, index)} defaultValue={assignee.time} size="small" style={{ width: 50, marginLeft: 5, marginRight: 5 }} />
                    <Dropdown overlay={<Menu>
                      {timeOptions.map((time) => <Menu.Item onClick={() => changeAssigneeTimeType(time.value, index)} key={time.value}>{time.label}</Menu.Item>)}
                    </Menu>}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {assignee.timeType} <Icon type="down" />
                      </a>
                    </Dropdown>
                  </div> */}
                </div>
              }
              )}
            </div>
            <div className="error-message"><Text type="danger"> {errorMessage} </Text></div>
          </Card>
        </div>
      }
      {
        currentStep == 4 &&
        <div className="routes-content">
          <Card className="routes-card route-detail-card" bordered={true}>
            <Title level={4} style={{ color: "#000", fontWeight: 600, fontSize: 16, textAlign: "left" }}>Route Details</Title>
            <div>
              <RouteForm initialValues={initialRouteFormValues} />
            </div>
          </Card>
        </div>
      }
      <FooterButton onNext={onNext} currentStep={currentStep} isUpdate={params.routeId} onPrevious={onPrevious} />
      <Modal
        title="Add Custom Schedule"
        okText="Add Custom Schedule"
        okButtonProps={{ disabled: !scheduleName || !startTime || !endTime || selectedDays.length === 0 }}
        className="custom-schedule-modal"
        visible={visibleModal}
        onOk={onClickAddScheduleHandler}
        onCancel={() => setVisibleModal(false)}
      >
        <div className="schedule-day-time-container">
          <Row type="flex">
            <Col span={12}>
              <div className="day-label">
                Day
              </div>
              <div className="day-value">{selectedDays.join(", ")}</div>
            </Col>
            <Col span={12}>
              <div className="day-label">
                Time
              </div>
              <div className="day-value">
                {startTime && moment(startTime, "HH:mm").format("HH:mm")} - {endTime && moment(endTime, "HH:mm").format("HH:mm")}
              </div>
            </Col>
          </Row>
        </div>
        <div className="input-container">
          <div className="label">Schedule Name <span className="required-star">*</span></div>
          <div><Input value={scheduleName} placeholder="Enter schedule name" onChange={(e) => setScheduleName(e.target.value)} /></div>
        </div>
      </Modal>
    </Spin>
  </div>;
};

const mapStateToProps = state => {
  return {
    studyDetailValues: getFormValues("StudyDetailForm")(state),
    routeFormValues: getFormValues("routeForm")(state),
    facilityList: state.facilities.internalArray,
    radiologistList: state.internalRadiologists.array,
    providerList: state.providers.array,
    activeRadiologists: state.internalRadiologists.activeRads,
    routeDetails: state.route.current,
    scheduleList: state.route.scheduleList,
    loading: state.loading.getRoute || state.loading.addRouting || state.loading.editRouting
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getFacilities: getInternalFacilitiesAction, getRadiologistsAction: getInternalRadiologistsAction, getProviders, getInternalRadiologistsStatus: getInternalRadiologistsStatusAction, createRouteRequest: postCreateRouteRequestAction, getSingleRoute: getSingleRouteRequestAction, updateRouteRequest: editRouteRequestAction, getSchedules: getScheduleListRequestAction, createSchedule: postCreateScheduleRequestAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoute); 
